export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Müşteri Kartları',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Ekle',
        route: 'CustomersAdd',
        resource: 'CUSTOMERS_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Customers',
        resource: 'CUSTOMERS_LIST',
        action: 'read',
      },
      {
        title: 'Firma Kullanıcı Tipleri',
        route: 'CustomerUserTypes',
        resource: 'CUSTOMER_USER_TYPES_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Makine Kartları',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Ekle',
        route: 'MachineAdd',
        resource: 'MACHINES_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Machines',
        resource: 'MACHINES_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Servis Bakım',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Oluştur (Form 1)',
        route: 'ServicesAdd1',
        resource: 'SERVICES_ADD',
        action: 'read',
      },
      {
        title: 'Oluştur (Form 2)',
        route: 'ServicesAdd2',
        resource: 'SERVICES_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Services',
        resource: 'SERVICES_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Servis Talepleri',
    icon: 'EditIcon',
    children: [
      {
        title: 'Bekleyen Talepler',
        route: 'ServiceRequestWaiting',
      },
      {
        title: 'Tüm Talepler',
        route: 'ServiceRequests',
      },
    ],
  },
  {
    title: 'Raporlar',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Servis Bakım Raporu',
        route: 'ServiceReports',
        resource: 'SERVICE_REPORT',
        action: 'read',
      },
    ],
  },
  {
    title: 'Tanımlamalar',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Şehirler',
        route: 'Cities',
        resource: 'CITIES_LIST',
        action: 'read',
      },
      {
        title: 'İlçeler',
        route: 'Districts',
        resource: 'DISTRICTS_LIST',
        action: 'read',
      },
      {
        title: 'Makine Durumları',
        route: 'MachineStatuses',
        resource: 'MACHINE_STATUSES_LIST',
        action: 'read',
      },
      {
        title: 'Makine Modelleri',
        route: 'MachineModels',
        resource: 'MACHINE_MODELS_LIST',
        action: 'read',
      },
      {
        title: 'Makine Tipleri',
        route: 'MachineTypes',
        resource: 'MACHINE_TYPES_LIST',
        action: 'read',
      },
      {
        title: 'Makine Temizleme Teknolojileri',
        route: 'MachineCleanings',
        resource: 'MACHINE_CLEANINGS_LIST',
        action: 'read',
      },
      {
        title: 'Ücretlendirmeler',
        route: 'InvoicedStatuses',
        resource: 'INVOICED_LIST',
        action: 'read',
      },
      {
        title: 'Çalışma Sınıfları',
        route: 'WorkTypes',
        resource: 'WORK_TYPES_LIST',
        action: 'read',
      },
      {
        title: 'İş Tipleri',
        route: 'Jobs',
        resource: 'JOBS_LIST',
        action: 'read',
      },
      {
        title: 'Malzeme Cinsleri',
        route: 'MaterialTypes',
        resource: 'MATERIAL_TYPES_LIST',
        action: 'read',
      },
      {
        title: 'Kullanılan Yağ',
        route: 'UsedOils',
        resource: 'USED_OILS_LIST',
        action: 'read',
      },
      {
        title: 'Kullanılan Proses',
        route: 'UsedProcesses',
        resource: 'USED_PROCESSES_LIST',
        action: 'read',
      },
      {
        title: 'Kullanılan Ürün',
        route: 'UsedProducts',
        resource: 'USED_PRODUCTS_LIST',
        action: 'read',
      },
      {
        title: 'Görünümler',
        route: 'Appearances',
        resource: 'APPEARANCES_LIST',
        action: 'read',
      },
      {
        title: 'Yabancı Yağ Oranları',
        route: 'ForeignOilRatios',
        resource: 'FOREIGN_OIL_RATIOS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kullanıcılar',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Listele',
        route: 'Users',
        resource: 'USERS_LIST',
        action: 'read',
      },
      {
        title: 'Kullanıcı Seviyeleri',
        route: 'UserTypes',
        resource: 'USER_TYPES_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Sistem',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'SMTP Ayarları',
        route: 'Smtpconfig',
        resource: 'SMTP_CONFIG',
        action: 'read',
      },
      {
        title: 'Sistem Ayarları',
        route: 'Config',
        resource: 'CONFIG',
        action: 'read',
      },
    ],
  },
]
